const PageNotFound = () => {
    return (
        <>
            <section className="flex items-center h-full p-16 dark:text-gray-100">
                <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                    <div className="max-w-md text-center">
                        <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
                            <span className="sr-only">Error</span>404
                        </h2>
                        <p className="text-2xl font-semibold md:text-3xl">Sorry, wir haben keine Seite gefunden.</p>
                        <p className="mt-4 mb-8 dark:text-gray-400">Aber keine Sorge, auf unserer Homepage finden Sie noch viele andere Dinge.</p>
                        <a rel="noopener noreferrer" href="/"
                           className="px-8 py-3 font-semibold rounded bg-violet-400 text-gray-900">Zurück zur Hauptseite</a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PageNotFound;